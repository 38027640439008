import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { shared_service } from "app/shared/shared.service";

import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { ashcorp_table, I_TableType } from "./ashcorp-table.types";
import { navigation_service } from "app/core/navigation/navigation.service";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component(
    {
        selector: 'ashcorp-table',
        templateUrl: './ashcorp-table.component.html',
        changeDetection: ChangeDetectionStrategy.Default,
        animations: [
            trigger('toggle', [
                state('close', style({ height: '0px', minHeight: '0', opacity: 0 })),
                state('open', style({ height: '*' })),
                transition('open <=> close', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            ]),
        ]
    }
)

export class ashcorp_table_Component<Type extends I_TableType> implements AfterViewInit {
    @Input() component: any;
    @Input() table: ashcorp_table<Type>;

    @ViewChild(MatSort) sortor: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    constructor(public _: navigation_service, public _shared_service: shared_service) { }

    ngAfterViewInit(): void {
        this.table.data_display.sort = this.sortor;
        this.table.data_display.paginator = this.paginator;

        combineLatest([
            this._.search$,
            this.table.filter$
        ]).pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(
            ([s, f]) => {
                let _type = this.component._type.table;
                let data = this.table.data_default;
                if (f.length > 0) {
                    data = data.filter((item: any) => {
                        let fit = true;
                        f.forEach((c: any) => {
                            if (!_type.filter(item, c)) {
                                fit = false;
                                return;
                            }
                        });
                        return fit;
                    });
                }
                this.table.data_display.data = data;
                if (s.length >= 0) {
                    this.table.data_display.filter = s.trim().toLowerCase();
                }
            }
        );
        this._.search("");
    }

    number(header: string, value: any): boolean {
        return header != "Id" && typeof value === 'number' && isFinite(value);
    }

    tracker(index: number, item: any): any {
        return item.id || index;
    }

    event(action: Function, params: any, idx: number, item: any) {
        this.component[action.name](params, idx, item);
    }
}